import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Apartados from './pages/Apartados';
import Invitaciones from './pages/Invitaciones';
import Solicitudes from './pages/Solicitudes';
import Login from './pages/Login';
import Auth from './context/auth/states';
import tokenAuth from './config/tokenAuth';
import Guests from './context/guests/states';
import EventosState from './context/eventos/states';
import Alertas from './context/alerts/states';
import SolicitudesState from './context/solicitudes/states';
import SeccionesState from './context/secciones/states';
import MultimediaState from './context/multimedia/states';
import MULTIMEDIA from './pages/Multimedia';
import Eventos from './pages/Eventos';
import PasesState from './context/pases/states';
import PASES from './pages/Pases';
import Configuraciones from './pages/Configuraciones';
import { Loading } from './elementos/Loading';
import PaseTemporal from './pages/PaseTemporal';
import TempporalState from './context/temporal/states';

const token = localStorage.getItem('token');
if(token){
  tokenAuth(token);
}


function App() {  
  return (<Auth>
    <Alertas>
      <EventosState>
        <Guests>
          <SolicitudesState>
            <MultimediaState>
              <SeccionesState>
                <PasesState>
                  <TempporalState>
                    <Router>
                      <Routes>
                        <Route exact path="/login"                      Component={Login}/>
                        <Route exact path="/dashboard"                  Component={Dashboard}/>
                        <Route exact path="/apartados"                  Component={Apartados}/>
                        <Route exact path="/invitaciones"               Component={Invitaciones}/>
                        <Route exact path="/solicitudes"                Component={Solicitudes}/>
                        <Route exact path="/multimedia"                 Component={MULTIMEDIA}/>
                        <Route exact path="/eventos"                    Component={Eventos}/>
                        <Route exact path="/pases"                      Component={PASES}/>
                        <Route exact path="/config"                     Component={Configuraciones}/>
                        <Route exact path="/pass/temporal/:code_guest"  Component={PaseTemporal}/>
                        <Route exact path="/"                           Component={Dashboard}/>
                        {/* <Route exact path="/loading"              Component={Loading}/> */}
                        <Route path="/*" element={<Navigate replace to="/dashboard" />} />
                      </Routes>
                    </Router>
                  </TempporalState>
                </PasesState>
              </SeccionesState>
            </MultimediaState>
          </SolicitudesState>
        </Guests>
      </EventosState>
    </Alertas>
  </Auth>
  );
}

export default App;
