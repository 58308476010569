const plantillas = ({tipo = 0}) => {
    switch(tipo){
        case 0:
            break;
        case 2: // adkar
            return [
                "banner",
                "padres",
                "invitado",
                "iglesia",
                "recepcion",
                "dresscode",
                "obsequios",
                "consideraciones",
                "recuerdos",
                "confirmacion",
                "texto-biblico",
                "galeria",
                "ubicaciones"
            ]
        case 3: // jeimi
            return [
                "*button_play",
                "banner",
                "*frases",
                "padres",
                "invitado",
                "&invitado.1",
                "&invitado.2",
                "&invitado.3",
                "iglesia",
                "recepcion",
                "dresscode",
                "obsequios",
                "consideraciones",
                "recuerdos",
                "confirmacion",
                "&confirmacion.1",
                "&confirmacion.2",
                "&confirmacion.3",
                "texto-biblico",
                "galeria",
                "&galeria.1",
                "&galeria.2",
                "&galeria.3",
                "&galeria.4",
                "&galeria.5",
                "&galeria.6",
                "&galeria.7",
                "&galeria.8",
                "&galeria.9",
                "&galeria.10",
                "&galeria.11",
                "&galeria.12",
                "&galeria.13",
                "ubicaciones"
            ]
        case 1: // jaqmik
            return [
                "*button_play",
                "banner",
                "texto_biblico",
                "invitado",
                "padres",
                "ubicacion-religiosa",
                "ubicacion-recepcion",
                "ubicacion_religiosa",
                "ubicacion_recepcion",
                "dresscode",
                "consideraciones",
                "hospedaje_1",
                "hospedaje_2",
                "hospedaje_3",
                "hospedaje_4",
                "celebremos_juntos",
                "obsequios",
                "galeria_1",
                "galeria_2",
                "galeria_3",
                "galeria_4",
                "galeria_5",
                "galeria_6",
                "galeria_7",
                "galeria_8",
                "galeria_9",
                "galeria_10",
                "galeria_11",
                "galeria_12",
                "galeria_13",
                "galeria_14",
                "galeria_15",
                "galeria_16",
                "galeria_17",
                "galeria_18",
                "galeria_19",
                "galeria_20",
                "confirmar-asistencia"
            ]
        case 4: // katherine
            return [
                "banner",
                "frases",
                "invitado",
                "&invitado.1",
                "&invitado.2",
                "&invitado.3",
                "iglesia",
                "recepcion",
                "dresscode",
                "obsequios",
                "consideraciones",
                "recuerdos",
                "confirmacion",
                "texto-biblico",
                "galeria",
                "&galeria.1",
                "&galeria.2",
                "&galeria.3",
                "&galeria.4",
                "&galeria.5",
                "&galeria.6",
                "&galeria.7",
                "&galeria.8",
                "&galeria.9",
                "&galeria.10",
                "&galeria.11",
                "&galeria.12",
                "&galeria.13",
                "ubicaciones"
            ]
        case 5: // marmad
            return [
                "*button_play",
                "banner",
                "texto_biblico_1",
                "texto_biblico_2",
                "invitado",
                "padres",
                "ubicacion-religiosa",
                "ubicacion-recepcion",
                "ubicacion_religiosa",
                "ubicacion_recepcion",
                "dresscode",
                "consideraciones",
                "hospedaje_1",
                "hospedaje_2",
                "hospedaje_3",
                "hospedaje_4",
                "celebremos_juntos",
                "obsequios",
                "galeria_1",
                "galeria_2",
                "galeria_3",
                "galeria_4",
                "galeria_5",
                "galeria_6",
                "galeria_7",
                "galeria_8",
                "galeria_9",
                "galeria_10",
                "galeria_11",
                "galeria_12",
                "galeria_13",
                "galeria_14",
                "galeria_15",
                "galeria_16",
                "galeria_17",
                "galeria_18",
                "galeria_19",
                "galeria_20",
                "recuerdos",
                "*confirmar",
                "confirmar-asistencia"
            ]
        case 6: // katherine
            return [
                "*button_play",
                "banner",
                "frases",
                "invitado",
                "&invitado.1",
                "&invitado.2",
                "&invitado.3",
                "iglesia",
                "recepcion",
                "dresscode",
                "obsequios",
                "consideraciones",
                "recuerdos",
                "confirmacion",
                "texto-biblico",
                "galeria",
                "galeria_1",
                "galeria_2",
                "galeria_3",
                "galeria_4",
                "galeria_5",
                "galeria_6",
                "galeria_7",
                "galeria_8",
                "galeria_9",
                "galeria_10",
                "galeria_11",
                "galeria_12",
                "galeria_13",
                "galeria_14",
                "galeria_15",
                "galeria_16",
                "galeria_17",
                "galeria_18",
                "galeria_19",
                "galeria_20",
                "ubicaciones"
            ]
        default:
            break;
    }
}

export default plantillas;