import React, {useContext} from 'react';
import {
    Backdrop,
    Box,
    Modal,
    Fade,
    Typography,
    Stack,
    Alert,
    Grid2,
    styled
} from '@mui/material';
import {
    Eventos as eventosContext,
    Alertas as alertsContext
} from '../../context';
import { useState } from 'react';
import { BotonGuardar } from '../index';

const style = {
  position: 'absolute',
  padding: "0px",
  paddingX: "20px",
  paddingBottom: "20px",
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "8px"
};


const Campo = styled('input')({
    width: "100%",
    padding: "7px 14px",
    fontSize: "12px",
    borderRadius: "5px"
})

const Texto = styled('p')({
    margin: "0px",
    fontSize: "12px"
})

const CampoFecha = styled('input')({
    width: "100%",
    padding: "7px 14px",
    fontSize: "12px",
    borderRadius: "5px"
})

const Seleccionar = styled('select')({
    fontSize: "12px",
    padding: "7px 5px",
    width: "100%",
    borderRadius: "5px"
})
const Opcion = styled('option')({})



const TransitionsModal = ({ guardarInvitado, msgBtn = "Agregar", titulo="Nueva" }) => {
    const alertContext = useContext(alertsContext);
    const { alert, mostrarAlerta } = alertContext;

    const EventosContext = useContext(eventosContext);
    const { guardarEvento } = EventosContext;

    const [guest, saveGuest] = React.useState({
        nombre:'',
        tipo: 0,
        codigoVestimenta: 0,
        id_plantilla: 0,
        iglesia: '',
        salon: '',
        festejados: {},
        padres: {},
        telefono: '',
        email:'',
        reserved: 0,
        invitados: 0
    })

    const {
        nombre,
        tipo,
        codigoVestimenta,
        id_plantilla,
        padre,
        madre,
        iglesia,
        salon,
        padres,
        festejados,
        telefono,
        email, 
        invitados,
    } = guest;

    const onChange = e => {
        if(
            e.target.name === "padre"       ||
            e.target.name === "madre"       ||
            e.target.name === "padreNovia"  ||
            e.target.name === "padreNovio"  ||
            e.target.name === "madreNovio"  ||  
            e.target.name === "madreNovia"  
        ){
            console.log(e.target.value)
            saveGuest({
                ...guest,
                padres:{
                    ...guest.padres,
                    [e.target.name] : e.target.value
                }
            })
        }else if(
            e.target.name === "edad"        || 
            e.target.name === "festejado"   ||
            e.target.name === "nombreCorto" ||
            e.target.name === "novio"       ||
            e.target.name === "novia"
        ){
            saveGuest({
                ...guest,
                festejados:{
                    ...guest.festejados,
                    [e.target.name] : e.target.value
                }
            })
        }else{
            if(e.target.name==="tipo" && e.target.value == 1){
                saveGuest({
                    ...guest,
                    [e.target.name] : e.target.value,
                    festejados: {...guest.festejados, edad: 15}
                })    
            }else{
                saveGuest({
                    ...guest,
                    [e.target.name] : e.target.value
                })
            }
        }
    }

    const onChangeDt = e => {
        let someDate = new Date(e.target.value);
        saveGuest({
            ...guest,
            dtEvento: someDate.getTime() + 86400000
        });
    }
    
    const [open, setOpen] = React.useState(false);
    const [submitState, setSubmit] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSubmit = async e =>{
        e.preventDefault();
        // Validar que no haya campos vacios
        if(nombre.trim() === '' ){
            mostrarAlerta(`${nombre.trim() === '' ? "El nombre es requerido." : ""}`, "alerta-error")
            return;
        }

        if(await guest){
            let minimizar = await guardarEvento(guest);
            if(minimizar){
                setOpen(false)
            }
        }
    }

    return (
        <div>
            <Box sx={{width: '100%', textAlign: 'end', paddingRight: '20px !important', display: "grid", justifyContent:"end"}}>
                {/* <Boton onClick={handleOpen}>{msgBtn}</Boton> */}
                <BotonGuardar sx={{
                    fontSize: "12px",
                    width: "fit-content"
                }} onClick={handleOpen}>{msgBtn}</BotonGuardar>
            </Box>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
            }}>
                <Fade in={open}>
                <Box className="scrollBloque" sx={{...style, overflowY: "scroll", height: "70vh"}}>
                    <Grid2 container sx={{
                        position: "sticky",
                        top: "0px",
                        background: "white",
                        opacity: 1,
                        marginTop: "15px",
                        marginBottom: "5px"
                    }}>
                        <Grid2 size={{xs:10, sm:10, md:6, lg:6, xl: 6}}>
                            <Typography sx={{textAlign: 'start', fontSize: "20px !important"}} id="transition-modal-title" variant="h6" component="h2">
                                {titulo}
                            </Typography>
                        </Grid2>
                        <Grid2 size={{xs:2, sm:2, md:6, lg:6, xl: 6}}>
                            <Box sx={{width: '100%', textAlign: 'end', padding: '5px 0px', display: "grid", justifyContent: "end"}}>
                                <BotonGuardar sx={{fontSize: "12px", width: "fit-content"}} onClick={onSubmit}>
                                    Guardar
                                </BotonGuardar>
                            </Box>
                        </Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{xs: 12, ls: 12, md:5}}>
                            <Texto>Nombre del evento</Texto>
                            <Campo
                                id="nombre"
                                name="nombre"
                                value={nombre}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:3}}>
                            <Texto>Número de invitados</Texto>
                            <Campo
                                id="invitados"
                                name="invitados"
                                value={invitados}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:4}}>
                            <Texto>Fecha del evento</Texto>
                            <CampoFecha type="date" onChange={onChangeDt}/>
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:3}}>
                            <Texto>Tipo de evento</Texto>
                            <Seleccionar
                                id="tipo"
                                name="tipo"
                                value={tipo}
                                onChange={onChange}
                            >
                                <Opcion value={0}>Boda</Opcion>
                                <Opcion value={1}>XV</Opcion>
                                <Opcion value={2}>Cumpleaños</Opcion>
                                <Opcion value={3}>Bautizo</Opcion>
                                <Opcion value={4}>BabyShower</Opcion>
                            </Seleccionar>
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:3}}>
                            <Texto>Dresscode</Texto>
                            <Seleccionar
                                id="codigoVestimenta"
                                name="codigoVestimenta"
                                value={codigoVestimenta}
                                onChange={onChange}
                            >
                                <Opcion value={0}>Formal</Opcion>
                                <Opcion value={1}>Informal</Opcion>
                                <Opcion value={2}>Casual</Opcion>
                                <Opcion value={3}>De etiqueta</Opcion>
                            </Seleccionar>
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:3}}>
                            <Texto>Plantilla</Texto>
                            <Seleccionar
                                id="id_plantilla"
                                name="id_plantilla"
                                value={id_plantilla}
                                onChange={onChange}
                            >
                                <Opcion value={1}>Plantilla 1 - JAQMIK</Opcion>
                                <Opcion value={2}>Plantilla 2 - ADKAR</Opcion>
                                <Opcion value={3}>Plantilla 3 - JEIMI</Opcion>
                                <Opcion value={4}>Plantilla 4 - KATHERINE</Opcion>
                                <Opcion value={5}>Plantilla 5 - MARMAD</Opcion>
                                <Opcion value={5}>Plantilla 6 - FRANCO</Opcion>
                            </Seleccionar>
                        </Grid2>
                        {tipo===0?(<Grid2 size={{xs: 12, ls: 12, md:6}}>
                            <Grid2 container spacing={2}>
                                <Grid2 size={{xs: 12, ls: 12, md:6}}>
                                    <Texto>Nombre del Novio</Texto>
                                    <Campo
                                        id="novio"
                                        name="novio"
                                        value={festejados.novio}
                                        onChange={onChange}
                                    />
                                </Grid2>
                                <Grid2 size={{xs: 12, ls: 12, md:6}}>
                                    <Texto>Nombre del Novia</Texto>
                                    <Campo
                                        id="novia"
                                        name="novia"
                                        value={festejados.novia}
                                        onChange={onChange}
                                    />
                                </Grid2>
                                <Grid2 size={{xs: 12, ls: 12, md:6}}>
                                    <Texto>Padre del Novio</Texto>
                                    <Campo
                                        id="padreNovio"
                                        name="padreNovio"
                                        value={festejados.padreNovio}
                                        onChange={onChange}
                                    />
                                </Grid2>
                                <Grid2 size={{xs: 12, ls: 12, md:6}}>
                                    <Texto>Madre del Novio</Texto>
                                    <Campo
                                        id="madreNovio"
                                        name="madreNovio"
                                        value={festejados.madreNovio}
                                        onChange={onChange}
                                    />
                                </Grid2>
                                <Grid2 size={{xs: 12, ls: 12, md:6}}>
                                    <Texto>Padre de la Novia</Texto>
                                    <Campo
                                        id="padreNovia"
                                        name="padreNovia"
                                        value={festejados.padreNovia}
                                        onChange={onChange}
                                    />
                                </Grid2>
                                <Grid2 size={{xs: 12, ls: 12, md:6}}>
                                    <Texto>Madre de la Novia</Texto>
                                    <Campo
                                        id="madreNovia"
                                        name="madreNovia"
                                        value={festejados.madreNovia}
                                        onChange={onChange}
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>):(<Grid2 size={{xs: 12, ls: 12, md:6}}>
                            <Grid2 container spacing={4}>
                                <Grid2 size={{xs: 12, ls: 12, md:4}}>
                                    <Texto>Nombre del festejado</Texto>
                                    <Campo
                                        id="festejado"
                                        name="festejado"
                                        value={festejados.nombre}
                                        onChange={onChange}
                                    />
                                </Grid2>
                                <Grid2 size={{xs: 12, ls: 12, md:4}}>
                                    <Texto>Nombre del Padre</Texto>
                                    <Campo
                                        id="padre"
                                        name="padre"
                                        value={padre}
                                        onChange={onChange}
                                    />
                                </Grid2>
                                <Grid2 size={{xs: 12, ls: 12, md:4}}>
                                    <Texto>Nombre del Madre</Texto>
                                    <Campo
                                        id="madre"
                                        name="madre"
                                        value={madre}
                                        onChange={onChange}
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>)}
                        <Grid2 size={{xs: 12, ls: 12, md:4}}>
                            <Texto>Nombre(s) Corto(s)</Texto>
                            <Campo
                                id="nombreCorto"
                                name="nombreCorto"
                                value={festejados.nombreCorto}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:4}}>
                            <Texto>Salón</Texto>
                            <Campo
                                id="salon"
                                name="salon"
                                value={salon}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:4}}>
                            <Texto>Iglesia</Texto>
                            <Campo
                                id="iglesia"
                                name="iglesia"
                                value={iglesia}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:4}}>
                            <Texto>Telefóno</Texto>
                            <Campo
                                id="telefono"
                                name="telefono"
                                value={telefono}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, ls: 12, md:4}}>
                            <Texto>Email</Texto>
                            <Campo
                                id="email"
                                name="email"
                                value={email}
                                onChange={onChange}
                            />
                        </Grid2>
                    </Grid2>
                    {alert ? 
                        (
                            <Stack sx={{ width: '100%', paddingTop: '20px' }} spacing={2}>
                                <Alert className={`dark ${alert.category}`} severity="error">{alert.msg}</Alert>
                            </Stack>
                        )
                    : null}
                </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default TransitionsModal;